@media print {
  @import url('https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css');
  @page {
    size: landscape;
  }
  body * {
    display: block  !important;
  }
  
}
